import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

const Home = () => {


  useEffect(() => {
    
  }, [])

  return (
    <div>
      <div className="container">
        <h1 className="display-4">iizy</h1>
        <h5 className="text-muted small">Let's make web faster, safer and optimal</h5>
        <div className="text-center mt-5">
          <div className="gradient-text h1">You're welcome.</div>
          <div className="mt-4 h4">We're modernizing this website. Thank you</div>
          {/*<button className="btn btn-lg btn-primary rounded-1">Post Requirements</button>*/}
        </div>
        <div className="my-5 py-5 text-center h3 gradient-text">We consult, develop, host and maintain your websites and applications</div>
      </div>
    </div>
  )
}
export default Home
