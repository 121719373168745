const Footer = () => {
  return (
    <footer className="py-3 py-lg-5 px-lg-5">
      <div className="container">
        &copy; iizy
      </div>
    </footer>
  )
}
export default Footer
